// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mamilos-js": () => import("./../../../src/pages/mamilos.js" /* webpackChunkName: "component---src-pages-mamilos-js" */),
  "component---src-pages-midia-js": () => import("./../../../src/pages/midia.js" /* webpackChunkName: "component---src-pages-midia-js" */),
  "component---src-pages-mulher-pandemica-js": () => import("./../../../src/pages/mulher-pandemica.js" /* webpackChunkName: "component---src-pages-mulher-pandemica-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-page-teste-js": () => import("./../../../src/pages/page-teste.js" /* webpackChunkName: "component---src-pages-page-teste-js" */),
  "component---src-pages-sargaco-js": () => import("./../../../src/pages/sargaco.js" /* webpackChunkName: "component---src-pages-sargaco-js" */),
  "component---src-pages-seiva-js": () => import("./../../../src/pages/seiva.js" /* webpackChunkName: "component---src-pages-seiva-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-trabalhos-js": () => import("./../../../src/pages/trabalhos.js" /* webpackChunkName: "component---src-pages-trabalhos-js" */)
}

